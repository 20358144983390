import React from "react";
import { Card, Input } from "antd";
import { useTokenState } from "./AuthenticatedFetchProvider";
import ReactJson from "react-json-view";
import { useMemo } from "react";
import jwtDecode from "jwt-decode";
import { DateTime } from "luxon";
import { useState } from "react";
import { useEffect } from "react";

function Debug() {
  const [token, setToken] = useTokenState();

  const decodedJwt = useMemo(() => {
    try {
      return jwtDecode(token);
    } catch (ex) {
      return null;
    }
  }, [token]);

  const [exp, setExp] = useState("");

  useEffect(() => {
    let timeout = null;

    const fn = () => {
      setExp(DateTime.fromSeconds(decodedJwt.exp).toRelative());
      timeout = setTimeout(fn, (1000 % (new Date() - 1)) + 1);
    };

    fn();

    return () => clearTimeout(timeout);
  }, [decodedJwt.exp]);

  return (
    <Card title="Current JWT">
      <Input value={token} onChange={(e) => setToken(e.target.value)} />
      <p>{decodedJwt && <ReactJson src={decodedJwt} />}</p>
      <p>{exp}</p>
    </Card>
  );
}

export default Debug;
