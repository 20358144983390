import React from "react";
import "./Button.scss";

function Button({ children, onClick, url, plain, disabled }) {
  if (onClick) {
    return (
      <button type="button" className={plain ? "gm-button--plain" : "gm-button"} onClick={onClick} disabled={disabled}>
        {children}
      </button>
    );
  }
  if (url && !disabled) {
    return (
      <a className={plain ? "gm-button--plain" : "gm-button"} href={url}>
        {children}
      </a>
    );
  }
  return <div className={plain ? "gm-button--plain" : "gm-button"}>{children}</div>;
}

export default Button;
