import React, { createContext, useContext, useState, useMemo } from "react";

const TEMPLATE_STATIC_BASE = "https://storage.googleapis.com/gm-assets/templates/";
const IMAGES_ACCOUNT_HASH = "760Xdm8p8t_EHRSHQYjOMQ"; // FIXME: inline id

// https://storage.googleapis.com/gm-assets/templates/${template.id}/preview.jpg?t=${dateStamp}
// https://storage.googleapis.com/gm-assets/cards/${cardId}/index.html?v=${+new Date()}

const AppConfigContext = createContext();

function AppConfigProvider({ children }) {
  const [dateStamp] = useState(+new Date());

  const data = useMemo(
    () => ({
      templateStaticBase: TEMPLATE_STATIC_BASE,
      templatePreviewImageUrl(templateId) {
        return `${TEMPLATE_STATIC_BASE}${templateId}/preview.jpg?t=${dateStamp}`;
      },
      cardAssetPreviewImageUrl({ imageId, videoId }) {
        if (videoId) {
          return `https://videodelivery.net/${videoId}/thumbnails/thumbnail.gif?width=124&height=186&fit=fill`;
        }
        if (imageId) {
          return `https://imagedelivery.net/${IMAGES_ACCOUNT_HASH}/${imageId}/public`;
        }

        return "#";
      },
    }),
    [dateStamp]
  );
  return <AppConfigContext.Provider value={data}>{children}</AppConfigContext.Provider>;
}

export function useAppConfig() {
  return useContext(AppConfigContext);
}

export default AppConfigProvider;
