import React, { useState, useEffect, useRef } from "react";
import Button from "./Button";
import "./Popover.scss";

const POPOVER_MARGIN = 16;

function Popover({ children, closeFn, position }) {
  const [[width, height], setSize] = useState([null, null]);
  const popoverChildrenElement = useRef(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.contentBoxSize) {
          const borderBoxSize = Array.isArray(entry.borderBoxSize) ? entry.borderBoxSize[0] : entry.borderBoxSize;
          setSize([borderBoxSize.inlineSize, borderBoxSize.blockSize]);
        }
      });
    });

    resizeObserver.observe(popoverChildrenElement.current);
    return () => resizeObserver.disconnect();
  }, []);

  /*
  const calculatedHeight = Math.max(Math.min(height, window.innerHeight - 32), 32);

  const coverEverything = height <
  const favorAbove = position.top > window.innerHeight / 2;

  const candidateTopHighEdge = 16;
  const candidateTopLowEdge = window.innerHeight - calculatedHeight - 16;
  const candidateTopCentered = position.top - calculatedHeight / 2;

  const candidateTopBelow = position.top + (position.height / 2 || 0) + 16;
  const candidateTopAbove = position.top - (position.height / 2 || 0) - calculatedHeight - 16;
  */

  let popoverContentStyle = {
    top: "16px",
    left: "16px",
    bottom: "16px",
    right: "16px",
    opacity: 1,
  };

  if (position) {
    const calculatedHeight = Math.max(Math.min(height, window.innerHeight - POPOVER_MARGIN * 2), POPOVER_MARGIN * 2);

    const candidateTopBelow = position.top + (position.height / 2 || 0) + POPOVER_MARGIN;
    const candidateTopAbove = position.top - (position.height / 2 || 0) - calculatedHeight - POPOVER_MARGIN;

    const favorAbove = window.innerHeight - position.top < height + POPOVER_MARGIN * 2;

    const candidateTop = favorAbove ? candidateTopAbove : candidateTopBelow;

    const candidateTopHighEdge = POPOVER_MARGIN;
    const candidateTopLowEdge = window.innerHeight - calculatedHeight - POPOVER_MARGIN;

    const calculatedTop = Math.max(Math.min(candidateTop, candidateTopLowEdge), candidateTopHighEdge);

    /*
    const calculatedHeight = Math.max(Math.min(height, window.innerHeight - 32), 32);
    const calculatedTop = Math.max(Math.min(position.top - calculatedHeight / 2, window.innerHeight - calculatedHeight - 16), 16);
    */

    const calculatedWidth = Math.max(Math.min(width, window.innerWidth - POPOVER_MARGIN * 2), POPOVER_MARGIN * 2);
    const calculatedLeft = Math.max(Math.min(position.left - calculatedWidth / 2, window.innerWidth - calculatedWidth - 16), 16);

    popoverContentStyle = {
      top: `${calculatedTop}px`,
      left: `${calculatedLeft}px`,
      width: width == null ? undefined : `${calculatedWidth}px`,
      height: height == null ? undefined : `${calculatedHeight}px`,
      opacity: height != null ? 1 : 0,
    };
  }
  return (
    <div className="gm-popover">
      <button type="button" aria-label="Close Popover" className="gm-popover__background" onClick={closeFn} />
      <div className="gm-popover__content" style={popoverContentStyle}>
        {closeFn && (
          <div className="gm-popover__closer">
            <Button onClick={closeFn} plain>
              <svg width="44" height="44">
                <path className="gm-popover__closer-path" d="M14 14l16 16M14 30l16-16" />
              </svg>
            </Button>
          </div>
        )}
        <div ref={popoverChildrenElement} className="gm-popover__children">
          {children}
        </div>
      </div>
    </div>
  );
}

export default Popover;
