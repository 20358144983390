export function videoThumbnail(videoBlob) {
  const videoElement = document.createElement("video");
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const videoUrl = URL.createObjectURL(videoBlob);
  videoElement.setAttribute("crossorigin", "anonymous");
  videoElement.setAttribute("src", videoUrl);
  videoElement.load();

  return new Promise((resolve) => {
    videoElement.addEventListener("loadedmetadata", () => {
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
    });

    videoElement.addEventListener("canplay", function () {
      videoElement.currentTime = 0.001;
      ctx.drawImage(videoElement, 0, 0, videoElement.videoWidth, videoElement.videoHeight);
      canvas.toBlob((blob) => {
        URL.revokeObjectURL(videoUrl);
        resolve(blob);
      });
    });
  });
}
