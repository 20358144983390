import React, { useEffect, useState, useCallback } from "react";

import { Form, Modal, Input, Table, Tabs } from "antd";
import { useAuthenticatedFetch } from "./AuthenticatedFetchProvider";

const { TabPane } = Tabs;

function Notifications() {
  const authenticatedFetch = useAuthenticatedFetch();
  const [notifications, setNotifications] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editNotificationForm] = Form.useForm();
  const [editNotificationLoading, setEditNotificationLoading] = useState(false);
  // const [selectedNotification, setSelectedNotification] = useState(null);
  const [modalType, setModalType] = useState("email");

  useEffect(() => {
    if (!notifications) {
      authenticatedFetch("/api/notifications")
        .then((r) => r.json())
        .then((response) => {
          if (!response.error) {
            const tmp = { email: {}, sms: {} };
            response.notifications.email.forEach((notification) => {
              tmp["email"][notification.handle] = notification;
            });
            response.defaults.email.forEach((notification) => {
              if (!tmp["email"][notification.handle]) tmp["email"][notification.handle] = notification;
            });
            response.notifications.sms.forEach((notification) => {
              tmp["sms"][notification.handle] = notification;
            });
            response.defaults.sms.forEach((notification) => {
              if (!tmp["sms"][notification.handle]) tmp["sms"][notification.handle] = notification;
            });
            setNotifications(tmp);
          }
        });
    }
  }, [notifications, authenticatedFetch]);

  const showModal = useCallback(
    (rowData, type) => {
      console.log("Show Modal");
      if (notifications && notifications[type][rowData.id]) {
        const notification = notifications[type][rowData.id];
        // setSelectedNotification(notification);
        setModalType(type);
        editNotificationForm.setFieldsValue({ id: notification.handle ?? "", subject: notification.subject ?? "", body: notification.body ?? "", type: type });
      }
      setIsModalVisible(true);
    },
    [notifications, editNotificationForm]
  );

  const handleModalCancel = useCallback(() => {
    console.log("Modal Cancel");
    setIsModalVisible(false);
    editNotificationForm.resetFields();
    // setSelectedNotification(null);
  }, [editNotificationForm]);

  const handleAddNotificationFinish = (values) => {
    console.log("Received values of form: ", values);
    setEditNotificationLoading(true);
    const formData = new FormData();
    formData.append("handle", values.id);
    formData.append("subject", values.subject);
    formData.append("body", values.body);
    formData.append("type", values.type);
    const method = "PUT";
    authenticatedFetch(`/api/notifications`, {
      method,
      body: formData,
    })
      .then((r) => r.json())
      .then((response) => {
        if (!response.error) {
          var tmp = { ...notifications };
          tmp[values.type][response.handle] = response; //TODO
          setNotifications(tmp);
        }
        setEditNotificationLoading(false);
        setIsModalVisible(false);
        // setSelectedNotification(null);
        editNotificationForm.resetFields();
      });
  };

  const addNotificationMarkup = (
    <Modal
      title="Edit Notification"
      okText="Submit"
      visible={isModalVisible}
      onOk={editNotificationForm.submit}
      onCancel={handleModalCancel}
      okButtonProps={{
        loading: editNotificationLoading,
      }}
    >
      <Form
        name="add_notification"
        onFinish={handleAddNotificationFinish}
        form={editNotificationForm}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 5 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 16 },
        }}
      >
        {modalType == "email" && (
          <Form.Item name="subject" label="Subject" rules={[{ required: true, message: "Please provide a subject" }]}>
            <Input />
          </Form.Item>
        )}
        <Form.Item name="body" label={`Body${modalType == "email" ? " HTML" : ""}`} rules={[{ required: true, message: "Please provide a body" }]}>
          <Input.TextArea autoSize={{ minRows: 15 }} showCount maxLength={modalType == "email" ? 5000 : 200} />
        </Form.Item>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item hidden name="type">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );

  return (
    <div className="notifications">
      {addNotificationMarkup}
      <Tabs defaultActiveKey="1">
        <TabPane tab="Email" key="1">
          <Table
            columns={[{ title: "Title", dataIndex: "title", key: "title" }]}
            dataSource={Object.values(notifications?.email ?? {}).map((n) => {
              return { title: n.title, id: n.handle, key: n.handle };
            })}
            onRow={(record) => ({
              onClick: () => {
                showModal(record, "email");
              }, // click row
            })}
          />
        </TabPane>
        <TabPane tab="SMS" key="2">
          <Table
            columns={[{ title: "Title", dataIndex: "title", key: "title" }]}
            dataSource={Object.values(notifications?.sms ?? {}).map((n) => {
              return { title: n.title, id: n.handle, key: n.handle };
            })}
            onRow={(record) => ({
              onClick: () => {
                showModal(record, "sms");
              }, // click row
            })}
          />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default Notifications;
