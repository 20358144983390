import React, { useCallback, useContext, useReducer } from "react";

const PopoverManagerContext = React.createContext(null);

const ADD = Symbol("ADD");
const REMOVE = Symbol("REMOVE");

function reducer({ nextId, displayList }, { type, render, id }) {
  if (type === ADD) {
    return {
      nextId: nextId + 1,
      displayList: [...displayList, { id: nextId, render }],
    };
  }

  if (type === REMOVE) {
    return {
      nextId,
      displayList: displayList.filter(({ id: innerId }) => innerId !== id),
    };
  }

  return { nextId, displayList };
}

function DisplayListItem({ dispatch, item: { id, render } }) {
  const dismiss = useCallback(() => {
    dispatch({ type: REMOVE, id });
  }, [dispatch, id]);
  return render(dismiss);
}

function PopoverManager({ children }) {
  const [{ displayList }, dispatch] = useReducer(reducer, { nextId: 2, displayList: [] });
  return (
    <PopoverManagerContext.Provider value={dispatch}>
      {children}
      {displayList.map((displayListItem) => (
        <DisplayListItem key={displayListItem.id} dispatch={dispatch} item={displayListItem} />
      ))}
    </PopoverManagerContext.Provider>
  );
}

export const useCreatePopover = () => {
  const dispatch = useContext(PopoverManagerContext);
  return useCallback((render) => dispatch({ type: ADD, render }), [dispatch]);
};

export default PopoverManager;
