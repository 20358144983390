import "./MediaTile.scss";
import React from "react";

function MediaTile({ children, onClick, src }) {
  return onClick ? (
    <button type="button" className="media-tile" style={{ backgroundImage: `url(${src})` }} onClick={onClick}>
      {children}
    </button>
  ) : (
    <div className="media-tile" style={{ backgroundImage: `url(${src})` }}>
      {children}
    </div>
  );
}

export default MediaTile;
