import React, { useCallback, useRef } from "react";
import CardDataEditor from "./CardDataEditor";
import LivePreview from "./LivePreview";
import Button from "../widgets/Button";
import TextField from "../widgets/TextField";
import Select from "../widgets/Select";
import Heading from "../widgets/Heading";
import VStack from "../widgets/VStack";
import Popover from "../widgets/Popover";

import ScrollLock from "../widgets/ScrollLock";
import CardDataEditorProvider, {
  SET_TEMPLATE,
  SET_SETTINGS_FOR_TEMPLATE,
  SET_GIFTEE_PHONE,
  SET_GIFTEE_EMAIL,
  SET_DELIVERY_METHOD,
  SET_DELIVERY_TIME,
  useCardApi,
  useCardState,
  useCardDispatch,
} from "./CardEditorContext";
import ElementEditor from "./ElementEditor";
import PopoverManager, { useCreatePopover } from "../widgets/PopoverManager";
import HStack from "../widgets/HStack";

import ReactJson from "react-json-view";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import { useEffect } from "react";
import DateField from "../widgets/DateField";
import { useAppConfig } from "../../AppConfigProvider";
import { useState } from "react";
import Panel from "../widgets/Panel";
import MediaPicker from "./MediaPicker";
import { uploadNewMedia } from "./uploadNewMedia";
import { UnsavedChangesProvider, useUnsavedChangesFunction } from "../widgets/UnsavedChanges";

const DELIVERY_METHODS = [
  { value: "scheduled", label: "At a specific time on a specific day" },
  { value: "on_delivery", label: "When we find out it has likely arrived" },
  { value: "manual", label: "When you press the button" },
];

function TemplateTile({ template, dismiss }) {
  const dispatch = useCardDispatch();
  const appConfig = useAppConfig();
  return (
    <Button
      key={template.id}
      onClick={() => {
        dispatch({ type: SET_TEMPLATE, value: template.id });
        dismiss();
      }}
      plain
    >
      <div
        style={{
          width: 320,
          height: 240,
          backgroundColor: "red",
          backgroundImage: `url(${appConfig.templatePreviewImageUrl(template.id)})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      ></div>
      <div>
        <p>
          <b>{template.data?.title || "\u00a0"}</b>
        </p>
        <p>{template.data?.description || "\u00a0"}</p>
      </div>
    </Button>
  );
}

function PhoneInput({ value, onChange }) {
  const inputRef = useRef(null);
  const itiRef = useRef(null);

  useEffect(() => {
    const iti = intlTelInput(inputRef.current, {
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.18/js/utils.min.js",
      separateDialCode: true,
    });
    itiRef.current = iti;
    return () => iti.destroy();
  }, []);

  useEffect(() => {
    if (itiRef.current && inputRef.current.value !== value) {
      itiRef.current.setNumber(value ?? "");
    }
  }, [value]);

  return <input type="tel" placeholder="xxx xxx-xxxx" ref={inputRef} onChange={() => onChange(itiRef.current.getNumber())} />;
}

function FinalSettings() {
  const state = useCardState();
  const dispatch = useCardDispatch();
  const cardApi = useCardApi();

  const { current: data, updateToken } = state;

  const [uploadInProgress, setUploadInProgress] = useState(false);
  const uploadNewMediaPromise = useCallback(() => uploadNewMedia({ data, cardApi }), [data, cardApi]);

  const lock = useUnsavedChangesFunction();

  return (
    <VStack>
      {data && (
        <div style={{ minWidth: 300 }}>
          <VStack>
            <span>Recipient Phone:</span>
            <PhoneInput
              value={data.giftee_phone}
              onChange={(value) =>
                dispatch({
                  type: SET_GIFTEE_PHONE,
                  value,
                })
              }
            />
            <span>Recipient Email:</span>
            <TextField
              type="email"
              value={data.giftee_email}
              onChange={(value) =>
                dispatch({
                  type: SET_GIFTEE_EMAIL,
                  value,
                })
              }
            />
            <span>When to delivery this:</span>
            <Select
              values={DELIVERY_METHODS}
              value={data.delivery_method}
              onChange={(value) =>
                dispatch({
                  type: SET_DELIVERY_METHOD,
                  value,
                })
              }
            />
            {data.delivery_method === "scheduled" ? (
              <DateField
                value={data.delivery_time}
                onChange={(value) =>
                  dispatch({
                    type: SET_DELIVERY_TIME,
                    value,
                  })
                }
              />
            ) : null}
            {data.delivery_method === "manual" ? <Button onClick={() => alert("yeah that did nothing...")}>the button</Button> : null}
          </VStack>
        </div>
      )}
      {!!updateToken && (
        <Button
          onClick={async () => {
            const unlock = lock();
            try {
              setUploadInProgress(true);
              await uploadNewMediaPromise();
              await cardApi.save();
            } finally {
              setUploadInProgress(false);
              unlock();
            }
          }}
          disabled={uploadInProgress}
        >
          {uploadInProgress ? "Saving..." : "Save"}
        </Button>
      )}
    </VStack>
  );
}

function SelectTemplateMenu({ dismiss }) {
  const { templates } = useCardState();

  return templates ? (
    <VStack>
      <Heading>Pick one of these crazy templates</Heading>

      {templates
        .filter(({ active }) => active)
        .map((template) => (
          <TemplateTile key={template.id} template={template} dismiss={dismiss} />
        ))}
    </VStack>
  ) : null;
}

function CardActionMenu() {
  const createPopover = useCreatePopover();

  const createFancyPopover = useCallback(
    (render) => {
      return (e) => {
        const initiatorRect = e ? e.target.getBoundingClientRect() : null;

        createPopover((dismiss) => (
          <ScrollLock>
            <Popover
              closeFn={dismiss}
              position={
                initiatorRect
                  ? {
                      left: initiatorRect.left + initiatorRect.width / 2,
                      top: initiatorRect.top + initiatorRect.height / 2,
                    }
                  : null
              }
            >
              <div style={{ padding: "1rem" }}>{render(dismiss)}</div>
            </Popover>
          </ScrollLock>
        ));
      };
    },
    [createPopover]
  );

  const addMedia = useCallback(() => createFancyPopover((dismiss) => <MediaPicker dismiss={dismiss} onChange={alert} image video />)(), [createFancyPopover]);

  const cardApi = useCardApi();
  const state = useCardState();

  const [uploadInProgress, setUploadInProgress] = useState(false);
  const uiUploadNewMedia = useCallback(() => {
    setUploadInProgress(true);
    uploadNewMedia({ data: state.current, cardApi }).finally(() => {
      setUploadInProgress(false);
    });
  }, [cardApi, state]);

  return (
    <div
      style={{
        position: "fixed",
        right: "1rem",
        bottom: "1rem",
        padding: "0.5rem",
        backgroundColor: "lime",
      }}
    >
      <HStack>
        <Button onClick={addMedia}>Media Library</Button>
        {uploadInProgress ? "Uploading Content..." : <Button onClick={uiUploadNewMedia}>Upload Media</Button>}
      </HStack>
    </div>
  );
}

const EDITOR_INTRO = Symbol();
const EDITOR_TEMPLATE = Symbol();
const EDITOR_PREVIEW = Symbol();
const EDITOR_EDIT = Symbol();
const EDITOR_FINALIZE = Symbol();

function EditorPreview() {
  const state = useCardState();

  const dispatch = useCardDispatch();
  const createPopover = useCreatePopover();

  const { current: data } = state;
  const setSettingsForTemplate = useCallback((newData) => dispatch({ type: SET_SETTINGS_FOR_TEMPLATE, value: newData }), [dispatch]);

  const editElementFn = useCallback(
    (pageId, elementId, domElement) => {
      const initiatorRect = (domElement ?? document.body).getBoundingClientRect();

      if (elementId) {
        createPopover((dismiss) => (
          <ScrollLock>
            <Popover
              closeFn={dismiss}
              position={{
                left: initiatorRect.left + initiatorRect.width / 2,
                top: initiatorRect.top + initiatorRect.height / 2,
              }}
            >
              <div style={{ padding: "1rem" }}>
                <ElementEditor pageId={pageId} elementId={elementId} dismiss={dismiss} />
              </div>
            </Popover>
          </ScrollLock>
        ));
      } else {
        createPopover((dismiss) => (
          <ScrollLock>
            <Popover
              closeFn={dismiss}
              position={{
                left: initiatorRect.left + initiatorRect.width / 2,
                top: initiatorRect.top + initiatorRect.height / 2,
              }}
            >
              <div style={{ padding: "1rem" }}>Edit Everything!!!</div>
            </Popover>
          </ScrollLock>
        ));
      }
    },
    [createPopover]
  );

  const templateId = data?.template_id;

  return (
    <>{templateId ? <LivePreview templateId={templateId} data={data} setSettingsForTemplate={setSettingsForTemplate} editElementFn={editElementFn} /> : null}</>
  );
}

function EditorIntro() {
  return null;
}

function EditorFinalize() {
  return (
    <Panel>
      <FinalSettings />
    </Panel>
  );
}

function CardEdit() {
  const state = useCardState();

  const [editorMode, setEditorMode] = useState(EDITOR_PREVIEW);

  const { current: data } = state;

  const common = (
    <>
      <ReactJson src={data} collapsed name="data" />
      <div style={{ height: "5rem" }} />
      <CardActionMenu />

      <div
        style={{
          position: "fixed",
          left: "1rem",
          bottom: "1rem",
          padding: "0.5rem",
          backgroundColor: "magenta",
        }}
      >
        <HStack>
          <Button onClick={() => setEditorMode(EDITOR_INTRO)}>Intro</Button>
          <Button onClick={() => setEditorMode(EDITOR_TEMPLATE)}>Template</Button>
          <Button onClick={() => setEditorMode(EDITOR_EDIT)}>Edit</Button>
          <Button onClick={() => setEditorMode(EDITOR_PREVIEW)}>Preview</Button>
          <Button onClick={() => setEditorMode(EDITOR_FINALIZE)}>Finish</Button>
        </HStack>
      </div>
    </>
  );
  switch (editorMode) {
    case EDITOR_INTRO:
      return (
        <>
          <EditorIntro />
          {common}
        </>
      );
    case EDITOR_TEMPLATE:
      return (
        <>
          <SelectTemplateMenu dismiss={() => setEditorMode(EDITOR_EDIT)} />
          {common}
        </>
      );
    case EDITOR_EDIT:
      return (
        <>
          <CardDataEditor />
          {common}
        </>
      );
    case EDITOR_PREVIEW:
      return (
        <>
          <EditorPreview />
          {common}
        </>
      );
    case EDITOR_FINALIZE:
      return (
        <>
          <EditorFinalize />
          {common}
        </>
      );
    default:
      return null;
  }
}

function CardEditor({ id, editKey }) {
  return (
    <UnsavedChangesProvider>
      <CardDataEditorProvider id={id} key={id} editKey={editKey}>
        <PopoverManager>
          <CardEdit />
        </PopoverManager>
      </CardDataEditorProvider>
    </UnsavedChangesProvider>
  );
}

export default CardEditor;
