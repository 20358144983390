import React, { useCallback } from "react";
import "./Select.scss";

function Select({ values = [], value, onChange, readOnly }) {
  const onChangeCallback = useCallback((e) => onChange(e.target.value), [onChange]);
  return (
    <select className="gm-select" value={value} onChange={onChange ? onChangeCallback : null} readOnly={readOnly}>
      {values.map(({ value, label, key }) => (
        <option value={value} key={key || value}>
          {label || value}
        </option>
      ))}
    </select>
  );
}

export default Select;
