const IMAGES_ACCOUNT_HASH = "760Xdm8p8t_EHRSHQYjOMQ"; // FIXME: inline id

const MediaManager = ({ localMedia }) => {
  const isLocalId = (id) => localMedia.has(id);

  return {
    getVideoUrl(localOrPublicId) {
      if (!isLocalId(localOrPublicId)) {
        return `https://videodelivery.net/${localOrPublicId}/manifest/video.m3u8`;
      } else {
        return localMedia.get(localOrPublicId).url;
      }
    },
    getImageUrl(localOrPublicId) {
      if (!isLocalId(localOrPublicId)) {
        return `https://imagedelivery.net/${IMAGES_ACCOUNT_HASH}/${localOrPublicId}/public`;
      } else {
        return localMedia.get(localOrPublicId).url;
      }
    },
  };
};

export default MediaManager;
