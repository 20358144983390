import React, { useState, useCallback } from "react";
import "./FilePicker.scss";

function FilePicker({ children, onChange, accepts, plain }) {
  const [id] = useState(() => `file-picker-${Math.random().toString(16).slice(2)}`);

  const innerOnChange = useCallback(
    (e) => {
      onChange(e.target.files[0]);
      e.value = "";
    },
    [onChange]
  );

  return (
    // <div className="gm-file-picker">
    <>
      <input type="file" accepts={accepts} id={id} className="gm-file-picker__element" onChange={innerOnChange} />
      <label htmlFor={id} className={plain ? "gm-file-picker__label gm-file-picker__label--plain" : "gm-file-picker__label"}>
        {children}
      </label>
    </>
  );
}

export default FilePicker;
