import React from "react";
import "./HStack.scss";

function Item({ children }) {
  return <div className="gm-hstack__item">{children}</div>;
}

function HStack({ children }) {
  return (
    <div className="gm-hstack">
      <div className="gm-hstack__items">{React.Children.map(children, (child) => child && <Item key={child.key}>{child}</Item>)}</div>
    </div>
  );
}

export default HStack;
