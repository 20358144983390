import { useEffect } from "react";

function scrollLock() {
  const scrollPosition = window.pageYOffset;
  document.body.style.top = `-${scrollPosition}px`;
  document.body.style.overflow = "hidden";
  document.body.style.position = "fixed";
  document.body.style.width = "100%";

  return () => {
    document.documentElement.style.scrollBehavior = "auto";
    document.body.style.removeProperty("top");
    document.body.style.removeProperty("overflow");
    document.body.style.removeProperty("position");
    document.body.style.removeProperty("width");
    document.documentElement.scrollTop = scrollPosition;
    document.documentElement.style.removeProperty("scrollBehavior");
  };
}

function ScrollLock({ children }) {
  useEffect(() => scrollLock(), []);
  return children || null;
}

export default ScrollLock;
