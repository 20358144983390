import React, { createContext, useContext, useEffect, useState } from "react";
import { useCallback } from "react";
import { useMemo } from "react";

const UnsavedChangesContext = createContext(null);

const counterReducer = (a, b) => a + b;

export const UnsavedChangesProvider = ({ children }) => {
  const [useCount, adjustUseCount] = useState(counterReducer, 0);
  const needed = useCount > 0;

  useEffect(() => {
    const listener = () => "you have unsaved changes";

    if (needed) {
      window.addEventListener("beforeunload", listener);
      return () => window.removeEventListener("beforeunload", listener);
    }
  }, [needed]);

  const payload = useMemo(() => ({ adjustUseCount }), []);

  return <UnsavedChangesContext.Provider value={payload}>{children}</UnsavedChangesContext.Provider>;
};

export function useUnsavedChanges() {
  const { adjustUseCount } = useContext(UnsavedChangesContext);
  useEffect(() => {
    adjustUseCount(1);
    return () => adjustUseCount(-1);
  }, [adjustUseCount]);
}

export function useUnsavedChangesFunction() {
  const { adjustUseCount } = useContext(UnsavedChangesContext);
  return useCallback(() => {
    adjustUseCount(1);
    return () => adjustUseCount(-1);
  }, [adjustUseCount]);
}
