import React from "react";
import "./VStack.scss";

function Item({ children }) {
  return <div className="gm-vstack__item">{children}</div>;
}

function VStack({ children }) {
  return <div className="gm-vstack">{React.Children.map(children, (child) => child && <Item key={child.key}>{child}</Item>)}</div>;
}

export default VStack;
