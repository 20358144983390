import React, { useCallback } from "react";
import MediaTile from "./MediaTile";
import FilePicker from "../widgets/FilePicker";
import { useAppConfig } from "../../AppConfigProvider";
import Text from "../widgets/Text";
import { useCardApi, useCardState } from "./CardEditorContext";
import HStack from "../widgets/HStack";
import VStack from "../widgets/VStack";

function MediaPicker({ onChange, video = false, image = false }) {
  const cardApi = useCardApi();
  const appConfig = useAppConfig();
  const { localAttachments, current: { attachments } = {} } = useCardState();

  const setBlob = useCallback(
    (blob) => {
      if (/^image\//.test(blob.type)) {
        return cardApi.addImage(blob);
      } else if (/^video\//.test(blob.type)) {
        return cardApi.addVideo(blob);
      }
      console.log("The Blob you picked is: ", blob);
    },
    [cardApi]
  );

  return (
    <VStack>
      <HStack>
        <FilePicker
          accepts="image/jpeg, image/png, image/webp, video/mp4, video/x-matroska, video/quicktime, video/x-msvideo, video/x-flv, video/MP2T, video/MP1S, video/MP2P, video/3gpp, video/webm, video/mpeg"
          onChange={setBlob}
        >
          Add an image or video from your device
        </FilePicker>
      </HStack>
      {image && (
        <>
          <Text>Images</Text>
          <HStack>
            {attachments?.images?.map?.((imageId) => (
              <MediaTile
                key={imageId}
                onClick={() => onChange(imageId)}
                onRemoveClick={() => cardApi.deleteImage(imageId)}
                src={appConfig.cardAssetPreviewImageUrl({ imageId })}
              />
            ))}
            {localAttachments?.map?.(({ id, type }) => (type === "image" ? <MediaTile key={id} onClick={() => onChange(id)} src={id} /> : null))}
          </HStack>
        </>
      )}
      {video && (
        <>
          <Text>Videos</Text>
          <HStack>
            {attachments?.videos?.map?.((videoId) => (
              <MediaTile
                key={videoId}
                onClick={() => onChange(videoId)}
                onRemoveClick={() => cardApi.deleteVideo(videoId)}
                src={appConfig.cardAssetPreviewImageUrl({ videoId })}
              />
            ))}
            {localAttachments?.map?.(({ id, type, previewImageUrl }) =>
              type === "video" ? <MediaTile key={id} onClick={() => onChange(id)} src={previewImageUrl} /> : null
            )}
          </HStack>
        </>
      )}
    </VStack>
  );
}

export default MediaPicker;
