import React, { useCallback } from "react";
import TextField from "../widgets/TextField";
import { SET_CARD_PAGE_ENTRY, useCardDispatch, useCardState } from "./CardEditorContext";
import MediaPicker from "./MediaPicker";

function ElementEditor({ pageId, elementId, dismiss }) {
  const { current: data, settingsForTemplate } = useCardState();
  const dispatch = useCardDispatch();
  const page = data.pages.find((page) => page.id === pageId);
  const pageSettings = settingsForTemplate.get(page.type);
  const elementSettings = pageSettings.find((pageSetting) => pageSetting.id === elementId);
  const type = elementSettings?.type;

  const content = page.elements.find((element) => element.id === elementId)?.content ?? "";

  const updateElement = useCallback(
    (pageId, elementId, value) => {
      dispatch({
        type: SET_CARD_PAGE_ENTRY,
        value: { pageId, elementId, value },
      });
    },
    [dispatch]
  );

  return (
    <React.Fragment>
      {(type === "text" || type == null) && (
        <TextField placeholder="Enter text here" value={content} onChange={(value) => updateElement(pageId, elementId, value)} />
      )}
      {type === "image" && (
        <MediaPicker
          image
          value={content}
          onChange={(image) => {
            updateElement(pageId, elementId, image);
            dismiss();
          }}
        />
      )}
      {type === "video" && (
        <MediaPicker
          video
          value={content}
          onChange={(video) => {
            updateElement(pageId, elementId, video);
            dismiss();
          }}
        />
      )}
    </React.Fragment>
  );
}

export default ElementEditor;
