import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Button, List, Row, Col } from "antd";
import { useAuthenticatedFetch } from "./AuthenticatedFetchProvider";
import { useAppConfig } from "./AppConfigProvider";

function MerchantDashboard() {
  const [cards, setCards] = useState(null);
  const [templates, setTemplates] = useState(null);
  const authenticatedFetch = useAuthenticatedFetch();
  const appConfig = useAppConfig();

  useEffect(() => {
    if (!templates) {
      authenticatedFetch("/api/templates")
        .then((r) => (r.ok ? r.json() : Promise.reject(r)))
        .then((response) => {
          setTemplates(response);
        })
        .catch((ex) => {
          console.warn(ex);
        });
    }
  }, [templates, authenticatedFetch]);

  useEffect(() => {
    if (!cards) {
      authenticatedFetch("/api/cards")
        .then((r) => (r.ok ? r.json() : Promise.reject(r)))
        .then(setCards)
        .catch((ex) => {
          console.warn(ex);
        });
    }
  }, [cards, authenticatedFetch]);

  const addCard = useCallback(() => {
    authenticatedFetch("/api/cards", {
      method: "POST",
    }).then(() => {
      setCards(null);
    });
  }, [authenticatedFetch]);

  const addTemplate = useCallback(() => {
    authenticatedFetch("/api/templates", {
      method: "POST",
    })
      .then(() => {
        setTemplates(null);
      })
      .catch((ex) => {
        console.warn(ex);
      });
  }, [authenticatedFetch]);

  return (
    <Row gutter={[24, 24]}>
      <Col span={12}>
        <Card
          title="Cards"
          extra={
            <Button type="link" onClick={addCard}>
              Add Card
            </Button>
          }
        >
          <List
            bordered
            dataSource={cards || []}
            renderItem={({ id: cardId, owner_key: editKey }) => (
              <List.Item>
                {cardId}{" "}
                <Link to={`/view/${cardId}`} target="_blank">
                  View
                </Link>{" "}
                <Link to={`/edit/${cardId}/${editKey}`} target="_blank">
                  Edit
                </Link>{" "}
                <a
                  href={`${appConfig.templateStaticBase.replace(/\/templates\/$/, "/cards/")}/${cardId}/index.html?v=${+new Date()}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  static
                </a>
              </List.Item>
            )}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card
          title="Templates"
          extra={
            <Button type="link" onClick={addTemplate}>
              Add template
            </Button>
          }
        >
          <List
            bordered
            dataSource={templates || []}
            renderItem={({ id }) => (
              <List.Item>
                <Link to={`/templates/${id}`}>{id}</Link>
              </List.Item>
            )}
          />
        </Card>
      </Col>
    </Row>
  );
}

export default MerchantDashboard;
