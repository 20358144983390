import { DateTime } from "luxon";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import HStack from "./HStack";

function numberOrNull(str) {
  return str != null ? Number(str) : str;
}

function DateField({ value: value, onChange, disabled }) {
  const valueDateTime = useMemo(() => {
    if (value) {
      const parsed = DateTime.fromISO(value);
      if (parsed.isValid) {
        return parsed;
      }
    }
    return DateTime.local().startOf("day");
  }, [value]);

  const [date, setDate] = useState(valueDateTime ? valueDateTime.toFormat("yyyy-MM-dd") : "");
  const [time, setTime] = useState(valueDateTime ? valueDateTime.toFormat("HH:mm:ss") : "");
  const [dateTime, setDateTime] = useState(valueDateTime ? valueDateTime.toFormat("yyyy-MM-dd'T'HH:mm:ss") : "");

  const isDesktopSafari = useMemo(
    () => /^(?:(?!chrome|android|mobile).)*safari/i.test(navigator.userAgent) && (!navigator.maxTouchPoints || navigator.maxTouchPoints === 1),
    []
  );

  useEffect(() => {
    if (valueDateTime) {
      setDate(valueDateTime.toFormat("yyyy-MM-dd"));
      setTime(valueDateTime.toFormat("HH:mm:ss"));
      setDateTime(valueDateTime.toFormat("yyyy-MM-dd'T'HH:mm:ss"));
    } else {
      setDate("");
      setTime("");
      setDateTime("");
    }
  }, [valueDateTime, value]);

  const onDateChange = useCallback(
    (e) => {
      const str = e.target.value;
      setDate(str);

      const match = /(\d+)-(\d+)-(\d+)/.exec(str);
      if (match) {
        const [, yearString, monthString, dayString] = match;
        const year = numberOrNull(yearString);
        const month = numberOrNull(monthString);
        const day = numberOrNull(dayString);

        const newValue = valueDateTime.set({ year, month, day });

        onChange(newValue.toISO());
      } else {
        console.log("onDateChange", str, null);
        onChange(null);
      }
    },
    [onChange, valueDateTime]
  );

  const onTimeChange = useCallback(
    (e) => {
      const str = e.target.value;
      setTime(str);

      const match = /(\d+):(\d+)(?::(\d+))?/.exec(str);
      if (match) {
        const [, hourString, minuteString, secondString] = match;

        const hour = numberOrNull(hourString);
        const minute = numberOrNull(minuteString);
        const second = numberOrNull(secondString);

        const newValue = valueDateTime.set({ hour, minute, second });

        onChange(newValue.toISO());
      } else {
        onChange(null);
      }
    },
    [onChange, valueDateTime]
  );

  const onDateTimeLocalChange = useCallback(
    (e) => {
      const str = e.target.value;
      setDateTime(str);

      const match = /(\d+)-(\d+)-(\d+)T(\d+):(\d+)(?::(\d+))?/.exec(str);
      if (match) {
        const [, yearString, monthString, dayString, hourString, minuteString, secondString] = match;
        const year = numberOrNull(yearString);
        const month = numberOrNull(monthString);
        const day = numberOrNull(dayString);
        const hour = numberOrNull(hourString);
        const minute = numberOrNull(minuteString);
        const second = numberOrNull(secondString);

        const newValue = valueDateTime.set({ year, month, day, hour, minute, second });

        onChange(newValue.toISO());
      } else {
        onChange(null);
      }
    },
    [onChange, valueDateTime]
  );

  if (isDesktopSafari) {
    return (
      <HStack>
        <input type="date" step={1} value={date} onChange={onDateChange} disabled={disabled} />
        <input type="time" step={1} value={time} onChange={onTimeChange} disabled={disabled} />
      </HStack>
    );
  }

  return (
    <HStack>
      <input type="datetime-local" step={1} value={dateTime} onChange={onDateTimeLocalChange} disabled={disabled} />
    </HStack>
  );
}

export default DateField;
