import React, { createContext, useContext, useEffect, useState } from "react";
import { useMemo } from "react";

import { useAuthenticatedFetch } from "../AuthenticatedFetchProvider";

export const SubscriptionContext = createContext({ plans: [], activePlan: null, activeSubscriptions: [] });

const counterReducer = (a, b) => a + b;

export const SubscriptionProvider = ({ children }) => {
  const authenticatedFetch = useAuthenticatedFetch();
  const [plans, setPlans] = useState([]);
  const [activePlan, setActivePlan] = useState(null);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [useCount, adjustUseCount] = useState(counterReducer, 0);

  const needed = useCount > 0;

  useEffect(() => {
    if (loaded === false && needed) {
      authenticatedFetch("/api/subscriptions")
        .then((r) => r.json())
        .then((response) => {
          setPlans(response.plans.sort((a, b) => a.display_ranking - b.display_ranking));
          setActivePlan(response.plans.filter((p) => p.id === response.active_plan_id)[0]);
          setActiveSubscriptions(response.active_subscriptions);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.error("can't fetch subscriptions... uh oh.");
        })
        .then(() => {
          setLoaded(true);
        });
    }
  }, [authenticatedFetch, loaded, needed]);

  const payload = useMemo(
    () => ({
      plans,
      activePlan,
      activeSubscriptions,
      loaded,
      adjustUseCount,
      reload: () => {
        setLoaded(false);
      },
    }),
    [activePlan, activeSubscriptions, loaded, plans]
  );

  return <SubscriptionContext.Provider value={payload}>{children}</SubscriptionContext.Provider>;
};

const usePlans = () => {
  const { adjustUseCount, plans } = useContext(SubscriptionContext);
  useEffect(() => {
    adjustUseCount(1);
    return () => adjustUseCount(-1);
  }, [adjustUseCount]);
  return plans;
};

const useActivePlan = () => {
  const { adjustUseCount, activePlan } = useContext(SubscriptionContext);
  useEffect(() => {
    adjustUseCount(1);
    return () => adjustUseCount(-1);
  }, [adjustUseCount]);
  return activePlan;
};

const useSubscriptions = () => {
  const { adjustUseCount, activeSubscriptions } = useContext(SubscriptionContext);
  useEffect(() => {
    adjustUseCount(1);
    return () => adjustUseCount(-1);
  }, [adjustUseCount]);
  return activeSubscriptions;
};

export default {
  usePlans,
  useActivePlan,
  useSubscriptions,
};
