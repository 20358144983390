import React, { useCallback } from "react";
import "./TextField.scss";

function TextField({ type = "text", placeholder, value, onChange, readOnly }) {
  const onChangeCallback = useCallback((e) => onChange(e.target.value), [onChange]);
  return (
    <input type={type} placeholder={placeholder} className="gm-text-field" value={value} onChange={onChange ? onChangeCallback : null} readOnly={readOnly} />
  );
}

export default TextField;
