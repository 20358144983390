import React from "react";
import { BrowserRouter, Switch, Route, Link, useLocation, useParams } from "react-router-dom";
import { Layout, Menu } from "antd";
import CardEditor from "./editor/CardEditor";
import Debug from "./Debug";
import CardView from "./CardView";
import Templates from "./Templates";
import TemplateEditor from "./TemplateEditor";
import TokenProvider, { useTokenState } from "./AuthenticatedFetchProvider";
import Account from "./Account";
import Credits from "./Credits";
import Notifications from "./Notifications";
import Login from "./Login";
import AppConfigProvider from "./AppConfigProvider";

import { SubscriptionProvider } from "./contexts/SubscriptionContext";

import "./App.css";

import MerchantDashboard from "./MerchantDashboard";

function AppLayout({ children }) {
  const { pathname } = useLocation();
  const [token] = useTokenState();

  if (/\/(?:view|edit)\//.test(pathname)) {
    return children;
  }
  if (!token) {
    return (
      <Layout className="layout">
        <Layout.Content style={{ padding: "100px 50px" }}>
          <Login />
        </Layout.Content>
      </Layout>
    );
  }
  return (
    <Layout className="layout">
      <Layout.Header>
        <div className="logo" />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[window.location.pathname]}>
          <Menu.Item key="/">
            <Link to="/">Templates</Link>
          </Menu.Item>
          <Menu.Item key="/merchant_dashboard">
            <Link to="/merchant_dashboard">Merchant Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="/account">
            <Link to="/account">Account</Link>
          </Menu.Item>
          <Menu.Item key="/credits">
            <Link to="/credits">Credits</Link>
          </Menu.Item>
          <Menu.Item key="/notifications">
            <Link to="/notifications">Notifications</Link>
          </Menu.Item>
          <Menu.Item key="/debug">
            <Link to="/debug">Debug</Link>
          </Menu.Item>
        </Menu>
      </Layout.Header>
      <Layout.Content style={{ padding: "0 50px" }}>{children}</Layout.Content>
      <Layout.Footer style={{ textAlign: "center" }}>©2022 Gift Messages by Eranzo Design</Layout.Footer>
    </Layout>
  );
}

function Editor() {
  const { id, key } = useParams();
  return <CardEditor id={id} editKey={key} />;
}

function App() {
  return (
    <AppConfigProvider>
      <TokenProvider>
        <SubscriptionProvider>
          <BrowserRouter>
            <AppLayout>
              <Switch>
                <Route exact path="/" render={() => <Templates />} />
                <Route exact path="/debug" render={() => <Debug />} />
                <Route exact path="/edit/:id/:key" render={() => <Editor />} />
                <Route exact path="/view/:id" render={() => <CardView />} />
                <Route exact path="/merchant_dashboard" render={() => <MerchantDashboard />} />
                <Route exact path="/account" render={() => <Account />} />
                <Route exact path="/credits" render={() => <Credits />} />
                <Route exact path="/notifications" render={() => <Notifications />} />
                <Route exact path="/templates" render={() => <Templates />} />
                <Route exact path="/templates/:id" render={() => <TemplateEditor />} />
                <Route path="*" render={() => 404} />
              </Switch>
            </AppLayout>
          </BrowserRouter>
        </SubscriptionProvider>
      </TokenProvider>
    </AppConfigProvider>
  );
}

export default App;
