import React, { useEffect, useRef } from "react";
import { useAppConfig } from "../../AppConfigProvider";
import { useCardApi } from "./CardEditorContext";
import "./LivePreview.scss";

function generateSettingsMap(cardTemplateElement) {
  if (!cardTemplateElement) {
    return null;
  }

  const templateElements = cardTemplateElement.querySelectorAll("[data-template-page-type]");

  return new Map(
    Array.prototype.map.call(templateElements, (templateElement) => {
      const templateSettings = [...templateElement.content.querySelectorAll("card-page-element")].map((cardPageElement) => ({
        id: cardPageElement.getAttribute("element-id"),
        type: cardPageElement.getAttribute("element-type"),
      }));
      return [templateElement.dataset.templatePageType, templateSettings];
    })
  );
}

function LivePreview({ templateId, data, setSettingsForTemplate, editElementFn }) {
  const appConfig = useAppConfig();
  const cardTemplateRef = useRef(null);
  const cardApi = useCardApi();

  useEffect(() => {
    const cardTemplateElement = cardTemplateRef.current;
    cardTemplateElement.setMediaManager(cardApi.mediaManager);
  }, [cardApi.mediaManager]);

  useEffect(() => {
    const cardTemplateElement = cardTemplateRef.current;
    const styleLink = document.createElement("link");
    const scriptTag = document.createElement("script");

    let cleanedUp = false;

    if (templateId) {
      console.log("template reloading...");
      fetch(`${appConfig.templateStaticBase}${templateId}/template.html?v=${+new Date()}`)
        .then((r) => r.text())
        .then((templateContent) => {
          if (!cleanedUp) {
            styleLink.href = `${appConfig.templateStaticBase}${templateId}/style.css?v=${+new Date()}`;
            styleLink.rel = "stylesheet";
            document.head.appendChild(styleLink);
            // cardTemplateElement.before(styleLink);

            scriptTag.defer = true;
            scriptTag.src = `${appConfig.templateStaticBase}${templateId}/script.js?v=${+new Date()}`;
            document.head.appendChild(scriptTag);
            // cardTemplateElement.before(scriptTag);

            cardTemplateElement.setTemplateContent(templateContent);

            const settingsMap = generateSettingsMap(cardTemplateElement);
            setSettingsForTemplate(settingsMap);

            cardTemplateElement.elementClicked = editElementFn;
          }
        });
    }

    return () => {
      cleanedUp = true;
      if (scriptTag) {
        scriptTag.remove();
      }
      if (styleLink) {
        styleLink.remove();
      }
    };
  }, [templateId, setSettingsForTemplate, editElementFn, appConfig.templateStaticBase]);

  /* temporary card settings stuff */
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (templateId && cardTemplateRef.current) {
        cardTemplateRef.current.setTemplateData(data);
      }
    }, 100);
    return () => clearTimeout(timeoutId);
  }, [data, templateId]);

  return !!templateId && <card-template class="live-preview" ref={cardTemplateRef} />;
}

export default LivePreview;
