import React, { createContext, useContext, useState, useEffect, useReducer } from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import jwtDecode from "jwt-decode";
import { useRef } from "react";
import MediaManager from "./MediaManager";
import { videoThumbnail } from "./videoUtil";

export const SET_DATA = Symbol("SET_DATA");
export const SET_SETTINGS_FOR_TEMPLATE = Symbol("SET_SETTINGS_FOR_TEMPLATE");
export const SET_TEMPLATE = Symbol("SET_TEMPLATE");
export const SET_TEMPLATE_LIST = Symbol("SET_TEMPLATE_LIST");
export const SET_GIFTEE_PHONE = Symbol("SET_GIFTEE_PHONE");
export const SET_GIFTEE_EMAIL = Symbol("SET_GIFTEE_EMAIL");
export const SET_DELIVERY_METHOD = Symbol("SET_DELIVERY_METHOD");
export const SET_DELIVERY_TIME = Symbol("SET_DELIVERY_TIME");
export const SET_CARD_PAGE_ENTRY = Symbol("SET_CARD_PAGE_ENTRY");
export const ADD_PAGE = Symbol("ADD_PAGE");
export const DELETE_PAGE = Symbol("DELETE_PAGE");
export const SET_CARD_ATTACHMENTS = Symbol("SET_CARD_ATTACHMENTS");
export const SET_CARD_LOCAL_ATTACHMENTS = Symbol("SET_CARD_LOCAL_ATTACHMENTS");
export const PIVOT_LOCAL_ATTACHMENT_TO_PUBLIC = Symbol("PIVOT_LOCAL_ATTACHMENT_TO_PUBLIC");

export const LAZY_OVERWRITE = Symbol("LAZY_OVERWRITE");

const CardDataStateContext = createContext(null);
const CardDataDispatchContext = createContext(null);
const CardDataApiContext = createContext(null);

function setCardPageEntry(lastState, pageId, elementId, value) {
  const { current: data, settingsForTemplate } = lastState;
  const page = data.pages.find((p) => p.id === pageId);
  const settingsForPage = settingsForTemplate.get(page?.type);
  return {
    ...lastState,
    current: {
      ...data,
      pages: data.pages.map((innerPage) =>
        innerPage.id === pageId
          ? {
              ...innerPage,
              elements: settingsForPage.map((setting) =>
                setting.id === elementId
                  ? { id: elementId, content: value }
                  : innerPage.elements.find((element) => element.id === setting.id) ?? {
                      id: setting.id,
                      content: "",
                    }
              ),
            }
          : innerPage
      ),
    },
  };
}

function pivotLocalAttachmentToPublic(lastState, localId, publicId) {
  const { current: data } = lastState;
  return {
    ...lastState,
    current: {
      ...data,
      pages: data.pages.map((innerPage) =>
        innerPage.elements.some((element) => element.content === localId)
          ? {
              ...innerPage,
              elements: innerPage.elements.map((element) => (element.content === localId ? { ...element, content: publicId } : element)),
            }
          : innerPage
      ),
    },
  };
}

function reducer(lastState, action) {
  const { type, value } = action;
  switch (type) {
    case SET_DATA:
      return { ...lastState, current: value.data ?? lastState.current, updateToken: value.updateToken, loaded: value.loaded };
    case SET_SETTINGS_FOR_TEMPLATE:
      return { ...lastState, settingsForTemplate: value };
    case SET_TEMPLATE:
      return { ...lastState, current: { ...lastState.current, template_id: value } };
    case SET_TEMPLATE_LIST:
      return { ...lastState, templates: value };
    case SET_GIFTEE_PHONE:
      return { ...lastState, current: { ...lastState.current, giftee_phone: value } };
    case SET_GIFTEE_EMAIL:
      return { ...lastState, current: { ...lastState.current, giftee_email: value } };
    case SET_DELIVERY_METHOD:
      return { ...lastState, current: { ...lastState.current, delivery_method: value } };
    case SET_DELIVERY_TIME:
      return { ...lastState, current: { ...lastState.current, delivery_time: value } };
    case SET_CARD_PAGE_ENTRY:
      return setCardPageEntry(lastState, value.pageId, value.elementId, value.value);
    case ADD_PAGE:
      return { ...lastState, current: { ...lastState.current, pages: [...lastState.current.pages, value] } };
    case DELETE_PAGE:
      return { ...lastState, current: { ...lastState.current, pages: lastState.current.pages.filter(({ id }) => id !== value.pageId) } };
    case SET_CARD_ATTACHMENTS:
      return { ...lastState, current: { ...lastState.current, attachments: value } };
    case SET_CARD_LOCAL_ATTACHMENTS:
      return { ...lastState, localAttachments: value };
    case PIVOT_LOCAL_ATTACHMENT_TO_PUBLIC:
      return pivotLocalAttachmentToPublic(lastState, value.localId, value.publicId);
    case LAZY_OVERWRITE:
      return { ...lastState, current: value };
    default:
  }

  console.warn("unhandled action for CardDataEditor", action);
  return lastState;
}

const DEFAULT_STATE = {};

function CardDataEditorProvider({ id, editKey, children }) {
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);

  const { updateToken, loaded, current } = state;

  const dataRef = useRef(null);

  const setLoadedData = useCallback((newData) => dispatch({ type: SET_DATA, value: newData }), [dispatch]);
  const setTemplateList = useCallback((newData) => dispatch({ type: SET_TEMPLATE_LIST, value: newData }), [dispatch]);

  useEffect(() => {
    dataRef.current = current;
  }, [current]);

  const refreshUpdateToken = useCallback(async () => {
    try {
      const { exp } = jwtDecode(updateToken);
      if (exp * 1000 > +new Date() - 60000) {
        // account for 60 seconds of clock skew
        return updateToken;
      }
    } catch (ex) {
      // need a new token anyways
    }

    console.log("need a new token...");

    const response = await fetch(id ? `/api/cards/${id}?key=${encodeURIComponent(editKey)}` : `/api/cards/${id}`);
    if (!response.ok) {
      throw "token_refresh_error";
    }

    const { update_token: newUpdateToken } = await response.json();
    setLoadedData({ updateToken: newUpdateToken });
    return newUpdateToken;
  }, [editKey, id, setLoadedData, updateToken]);

  const authenticatedFetch = useCallback(
    async (url, options = {}) => {
      const newUpdateToken = await refreshUpdateToken();
      return fetch(url, { ...options, headers: { ...options.headers, Authorization: `Bearer ${newUpdateToken}` } });
    },
    [refreshUpdateToken]
  );

  const [localMedia] = useState(() => new Map());
  const [mediaManager] = useState(() => MediaManager({ localMedia }));

  useEffect(
    () => () => {
      for (const [key, { previewImageBlob }] in localMedia.entries()) {
        URL.revokeObjectURL(key);
        if (previewImageBlob) {
          URL.revokeObjectURL(previewImageBlob);
        }
      }
      localMedia.clear();
    },
    [localMedia]
  );

  const cardApi = useMemo(
    () => ({
      save() {
        return authenticatedFetch(`/api/cards/${id}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            data: dataRef.current,
          }),
        });
      },
      uploadImage(imageBlob) {
        return new Promise((resolve, reject) => {
          const form = new FormData();
          form.append("file", imageBlob, imageBlob.name ?? `image-${+new Date()}`);

          authenticatedFetch(`/api/cards/${id}/image`, {
            method: "POST",
            body: form,
          })
            .then((r) => (r.ok ? r.json() : Promise.reject(r)))
            .then((res) => {
              if (res.image) {
                resolve(res.image.id);
                dispatch({
                  type: SET_CARD_ATTACHMENTS,
                  value: res?.card?.data?.attachments,
                });
              } else {
                reject(res);
              }
            })
            .catch((ex) => {
              console.warn(ex);
            });
        });
      },
      uploadVideo(videoBlob) {
        return new Promise((resolve, reject) => {
          const form = new FormData();
          form.append("file", videoBlob, videoBlob.name ?? `video-${+new Date()}`);

          authenticatedFetch(`/api/cards/${id}/video`, { method: "POST", body: "" })
            .then((r) => (r.ok ? r.json() : Promise.reject(r)))
            .then((res) => {
              const uploadURL = res?.video?.upload_url;
              const videoId = res?.video?.id;
              const attachments = res?.card?.data?.attachments;

              return fetch(uploadURL, {
                method: "POST",
                body: form,
              }).then((response) => {
                if (response.ok) {
                  resolve(videoId);
                  dispatch({
                    type: SET_CARD_ATTACHMENTS,
                    value: attachments,
                  });
                } else {
                  reject(response);
                }
              });
            })
            .catch((ex) => {
              console.warn(ex);
            });
        });
      },
      deleteImage(imageId) {
        authenticatedFetch(`/api/cards/${id}/image/${imageId}`, { method: "DELETE" })
          .then((r) => (r.ok ? r.json() : Promise.reject(r)))
          .then((res) => {
            const newAttachments = res?.card?.data?.attachments;
            if (newAttachments) {
              dispatch({
                type: SET_CARD_ATTACHMENTS,
                value: newAttachments,
              });
            }
          })
          .catch((ex) => {
            console.warn(ex);
          });
      },
      deleteVideo(videoId) {
        authenticatedFetch(`/api/cards/${id}/video/${videoId}`, { method: "DELETE" })
          .then((r) => (r.ok ? r.json() : Promise.reject(r)))
          .then((res) => {
            const newAttachments = res?.card?.data?.attachments;
            if (newAttachments) {
              dispatch({
                type: SET_CARD_ATTACHMENTS,
                value: newAttachments,
              });
            }
          })
          .catch((ex) => {
            console.warn(ex);
          });
      },
      addVideo(blob) {
        const url = URL.createObjectURL(blob);
        videoThumbnail(blob).then((previewImageBlob) => {
          const previewImageUrl = URL.createObjectURL(previewImageBlob);
          const lastEntry = localMedia.get(url);
          localMedia.set(url, { ...lastEntry, previewImageBlob, previewImageUrl });
          dispatch({ type: SET_CARD_LOCAL_ATTACHMENTS, value: [...localMedia.entries()].map(([id, v]) => ({ id, ...v })) });
        });
        localMedia.set(url, { type: "video", blob, url, publicId: null });
        dispatch({ type: SET_CARD_LOCAL_ATTACHMENTS, value: [...localMedia.entries()].map(([id, v]) => ({ id, ...v })) });
        return url;
      },
      addImage(blob) {
        const url = URL.createObjectURL(blob);
        localMedia.set(url, { type: "image", blob, url, publicId: null });
        dispatch({ type: SET_CARD_LOCAL_ATTACHMENTS, value: [...localMedia.entries()].map(([id, v]) => ({ id, ...v })) });
        return url;
      },
      async upload(localId) {
        const localMediaRecord = localMedia.get(localId);
        if (localMediaRecord.publicId) {
          return Promise.resolve(localMediaRecord.publicId);
        }
        const blob = localMediaRecord.blob;
        if (localMediaRecord.type === "image") {
          const imageId = await cardApi.uploadImage(blob);
          dispatch({ type: PIVOT_LOCAL_ATTACHMENT_TO_PUBLIC, value: { localId: localId, publicId: imageId } });
          const localMediaEntry = localMedia.get(localId);
          localMedia.delete(localId);
          localMedia.set(imageId, localMediaEntry);
          dispatch({ type: SET_CARD_LOCAL_ATTACHMENTS, value: [...localMedia.entries()].map(([id, v]) => ({ id, ...v })) });
          return null;
        }
        if (localMediaRecord.type === "video") {
          const videoId = await cardApi.uploadVideo(blob);
          dispatch({ type: PIVOT_LOCAL_ATTACHMENT_TO_PUBLIC, value: { localId: localId, publicId: videoId } });
          const localMediaEntry = localMedia.get(localId);
          localMedia.delete(localId);
          localMedia.set(videoId, localMediaEntry);
          dispatch({ type: SET_CARD_LOCAL_ATTACHMENTS, value: [...localMedia.entries()].map(([id, v]) => ({ id, ...v })) });
          return null;
        }
        return Promise.reject("type not supported");
      },
      mediaManager,
    }),
    [authenticatedFetch, id, localMedia, mediaManager]
  );

  // load card data
  useEffect(() => {
    if (!loaded) {
      fetch(id ? `/api/cards/${id}?key=${encodeURIComponent(editKey)}` : `/api/cards/${id}`)
        .then((r) => (r.ok ? r.json() : Promise.reject(r)))
        .then(({ data: newData, update_token: newUpdateToken }) => {
          setLoadedData({ data: newData, updateToken: newUpdateToken, loaded: true });
        })
        .catch((ex) => {
          console.warn(ex);
          setLoadedData({ loaded: true });
        });
    }
  }, [editKey, id, loaded, setLoadedData]);

  // load templates
  useEffect(() => {
    if (updateToken) {
      authenticatedFetch("/api/templates")
        .then((r) => (r.ok ? r.json() : Promise.reject(r)))
        .then(setTemplateList)
        .catch((ex) => {
          console.warn(ex);
        });
    } else {
      console.log("no update token to fetch templates");
    }
  }, [setTemplateList, authenticatedFetch, updateToken]);

  return (
    <CardDataDispatchContext.Provider value={dispatch}>
      <CardDataStateContext.Provider value={state}>
        <CardDataApiContext.Provider value={cardApi}>{children}</CardDataApiContext.Provider>
      </CardDataStateContext.Provider>
    </CardDataDispatchContext.Provider>
  );
}

export const useCardDispatch = () => useContext(CardDataDispatchContext);
export const useCardApi = () => useContext(CardDataApiContext);
export const useCardState = () => useContext(CardDataStateContext);

export default CardDataEditorProvider;
