import React, { useCallback } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Popover from "../widgets/Popover";
import { useCreatePopover } from "../widgets/PopoverManager";
import Button from "../widgets/Button";
import TextField from "../widgets/TextField";
import VStack from "../widgets/VStack";

import { SET_CARD_PAGE_ENTRY, ADD_PAGE, DELETE_PAGE, LAZY_OVERWRITE, useCardState, useCardDispatch } from "./CardEditorContext";
import Panel from "../widgets/Panel";
import MediaPicker from "./MediaPicker";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function CardDataPage({ page, pageId, settingsForPage }) {
  const createPopover = useCreatePopover();

  const dispatch = useCardDispatch();

  const updateElement = useCallback(
    (elementId, value) => {
      dispatch({
        type: SET_CARD_PAGE_ENTRY,
        value: { pageId, elementId, value },
      });
    },
    [dispatch, pageId]
  );

  const removePage = useCallback(() => {
    dispatch({
      type: DELETE_PAGE,
      value: { pageId },
    });
  }, [dispatch, pageId]);

  return settingsForPage ? (
    <Panel>
      <div style={{ display: "flex" }}>
        <div style={{ flex: "1 0 0%" }}>
          <VStack>
            {settingsForPage.map(({ id, type }) => (
              <React.Fragment key={id}>
                {type === "text" && (
                  <TextField
                    placeholder="Enter text here"
                    value={page.elements.find((element) => element.id === id)?.content ?? ""}
                    onChange={(value) => updateElement(id, value)}
                  />
                )}
                {type === "image" && (
                  <Button
                    onClick={() =>
                      createPopover((dismiss) => (
                        <Popover closeFn={dismiss}>
                          <MediaPicker
                            image
                            value={page.elements.find((element) => element.id === id)?.content ?? ""}
                            onChange={(image) => {
                              updateElement(id, image);
                              dismiss();
                            }}
                          />
                        </Popover>
                      ))
                    }
                  >
                    Pick Image
                  </Button>
                )}
                {type === "video" && (
                  <Button
                    onClick={() =>
                      createPopover((dismiss) => (
                        <Popover closeFn={dismiss}>
                          <MediaPicker
                            video
                            value={page.elements.find((element) => element.id === id)?.content ?? ""}
                            onChange={(video) => {
                              updateElement(id, video);
                              dismiss();
                            }}
                          />
                        </Popover>
                      ))
                    }
                  >
                    Pick Video
                  </Button>
                )}
              </React.Fragment>
            ))}
          </VStack>
        </div>
        <div style={{ flex: "0 0 4rem", paddingLeft: "1rem" }}>
          <VStack>
            <div style={{ textAlign: "right" }}>: : : : : :</div>
            <Button onClick={removePage}>Remove</Button>
          </VStack>
        </div>
      </div>
    </Panel>
  ) : null;
}

function CardDataEditor() {
  const { current: data, settingsForTemplate } = useCardState();
  const dispatch = useCardDispatch();

  const updateElement = useCallback(
    (pageId, elementId, value) => {
      dispatch({
        type: SET_CARD_PAGE_ENTRY,
        value: { pageId, elementId, value },
      });
    },
    [dispatch]
  );

  const onDragEnd = useCallback(
    (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const pages = reorder(data.pages, result.source.index, result.destination.index);

      dispatch({
        type: LAZY_OVERWRITE,
        value: {
          ...data,
          pages,
        },
      });
    },
    [data, dispatch]
  );

  return (
    !!settingsForTemplate && (
      <div style={{ maxWidth: "640px", width: "100%", margin: "32px auto 128px" }}>
        <VStack>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(droppableProvided) => (
                <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                  {data.pages.map((page, index) => (
                    <Draggable key={page.id} draggableId={page.id} index={index}>
                      {(draggableProvided) => (
                        <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} {...draggableProvided.dragHandleProps}>
                          <div style={{ position: "relative", paddingBottom: "1rem" }}>
                            <CardDataPage
                              key={page.id}
                              settingsForPage={settingsForTemplate.get(page.type)}
                              page={page}
                              pageId={page.id}
                              updateElement={updateElement}
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <VStack>
            {[...settingsForTemplate.keys()].map((pageType) => (
              <Button
                key={pageType}
                onClick={() => {
                  const nextId = String(+new Date());
                  dispatch({
                    type: ADD_PAGE,
                    value: { type: pageType, id: nextId, elements: [] },
                  });
                }}
              >
                Add {pageType} page
              </Button>
            ))}
          </VStack>
        </VStack>
      </div>
    )
  );
}
export default CardDataEditor;
